import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In this episode, I am talking with `}<b>{`Katerina Litvinova`}</b>{`.
She is Head of International Marketing at `}<a parentName="p" {...{
        "href": "https://www.rubrain.com/"
      }}>{`Rubrain`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.rubrain.com/"
      }}>{`Rubrain`}</a>{` is a Russian platform for freelancers.
It connects the best talent in Eastern Europe with companies all around the world.`}</p>
    <p>{`I met Katerina during the `}<a parentName="p" {...{
        "href": "https://websummit.com/"
      }}>{`WebSummit`}</a>{` in Lisbon and that’s how I discovered Rubrain and how it works!`}</p>
    <h2>{`Tell me about yourself. Where are you from?`}</h2>
    <p>{`I am from Moscow but I am living in London. When I was 25, I had a great career there as PR Manager for the main recruiting site in Russia.`}</p>
    <p>{`At some point, I felt that I spent all my time between career and traffic jams in Moscow (Moscow is famous for those…).
In that period I lost some people close to me and I started to question what to do next.`}</p>
    <p>{`I left my well-paid job and I decided to take the “Camino de Santiago”, for about 400 km.`}</p>
    <p>{`That made me think a lot and I realized that I didn’t want to work in an office all my life.`}</p>
    <p>{`In that time, I met my current business partner Vasily who is one of the founders of Rubrain. He also helped to build some other famous freelance site in Russia before this startup.`}</p>
    <p>{`I started working with him as a freelancer and I moved to London.`}</p>
    <p>{`I worked remotely and it worked great for me. I must say that I am very passionate about remote working.`}</p>
    <p>{`After few years, we had good experience with freelancing and working remotely.`}</p>
    <p>{`So, we decided to start Rubrain because more people were moving in the freelancing world. Many of these people are really qualified and some of them have already worked in Silicon Valley.`}</p>
    <p>{`But, sometimes they need to come back in Russia for personal circumstances.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*Zut56_CzUDdxkPok",
        "alt": "Russia"
      }}></img></p>
    <h2>{`How does your daily work looks like?`}</h2>
    <p>{`So, you are living in London. How do you cooperate with the rest of the team?`}</p>
    <p>{`I am in London, and we don’t have a physical office. All the rest of the team is in Russia.`}</p>
    <p>{`We work remote and we use Skype and other tools to collaborate remotely.
It is very efficient for us.`}</p>
    <h2>{`What is your typical workday?`}</h2>
    <p>{`I work from anywhere and usually, the day starts with a company meetup.
We communicate all day and keep really close using Skype and Telegram.`}</p>
    <p>{`I use part of my day going to meetups and events. In this way, I can stay in touch with the international community while finding more clients.
Our pricing is very appealing for many companies in Europe.`}</p>
    <p>{`Well, I have also a family and try to spend a good amount of time with them.`}</p>
    <h2>{`Tell me more about Rubrain`}</h2>
    <p>{`We are more like an outsourcing company and we compete with sites like `}<a parentName="p" {...{
        "href": "https://www.toptal.com/"
      }}>{`TopTal`}</a>{`.`}</p>
    <p>{`We outsource top Russian-speaking developers and designers to clients worldwide.`}</p>
    <p>{`At the moment, we are working with the Russian, European and American market.
Unlike TopTal, we provide a project manager for every project.`}</p>
    <p>{`We are the intermediary between the client and the developers. We make sure that we build the right things eliminating all these language barriers.`}</p>
    <p>{`Of course, some clients using Rubrain may have already a development team. In that case, if developers on both sides can speak to each other, they can do that with ease.`}</p>
    <p>{`We are there to help the client with anything they may need: from tech specs to business details. In this way, developers and designers will always know what is the right things to build.`}</p>
    <h2>{`Which kind of engineers do you have in Rubrain?`}</h2>
    <p>{`At the moment, we have the typical developers and designers but we are looking to other categories like Artificial Intelligence, IOT and Machine Learning.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Not sure about Artificial Intelligence, IOT and Machine Learning?
Some of the best courses for learning about them.
1.`}<a parentName="p" {...{
          "href": "https://www.coursera.org/learn/machine-learning"
        }}>{`Machine Learning with Andrew Ng`}</a></p>
      <p parentName="blockquote">{`2.`}<a parentName="p" {...{
          "href": "https://www.coursera.org/specializations/iot"
        }}>{`An Introduction to the Internet of Things`}</a></p>
      <p parentName="blockquote">{`3.`}<a parentName="p" {...{
          "href": "https://www.udacity.com/course/intro-to-artificial-intelligence--cs271"
        }}>{`Intro to Artificial Intelligence`}</a></p>
    </blockquote>
    <h2>{`Is it very difficult to find freelancer now?`}</h2>
    <p>{`It is difficult to find high skilled developers and freelancers in Eastern Europe.
That’s our specialty . We are not looking for “good enough” developers.`}</p>
    <p>{`So, we screen them carefully and that’s one of the tasks of our CTO.`}</p>
    <h2>{`Do you focus only on talent from Russia or also other countries?`}</h2>
    <p>{`No, we are focusing on Russian speaking developers wherever they are. Russia, Ukraine, and Byelorussia are some of the main countries.`}</p>
    <p>{`We are also considering developers from other Eastern Europe countries. The only requirements is that they can speak English.`}</p>
    <h2>{`How does the customer find you?`}</h2>
    <p>{`As a customer, can you tell me the process from idea to real-world lunch using Rubrain? How the customer finds you? How do you manage all the process until you release the final product?
They usually find us through startup events and Product Hunt.`}</p>
    <p>{`Usually, companies contact me first.`}</p>
    <p>{`I will be their first point of contact for understanding their needs and pricing. I will also explain the process moving forward. We explain how Rubrain works from processes on the daily basis to payments.`}</p>
    <p>{`We are the intermediary between their needs and our workforce.`}</p>
    <p>{`They will never need to deal with the developers and designers.`}</p>
    <h2>{`How do you handle payments?`}</h2>
    <p>{`How do you handle payments this between you, the technical talent and the customer?`}</p>
    <p>{`Developers have their pricing. We charge our 20% to the clients on top of that for all our services during the project.`}</p>
    <p>{`We secure the money in an escrow service. It will only pay to the developers once they deliver the project and the customer accepts it.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Here few other alternatives to Rubrain:`}</p>
      <p parentName="blockquote">{`1.`}<a parentName="p" {...{
          "href": "https://www.freelancer.com/"
        }}>{`Freelancer`}</a></p>
      <p parentName="blockquote">{`2.`}<a parentName="p" {...{
          "href": "https://www.peopleperhour.com/"
        }}>{`PeoplePerHour`}</a></p>
      <p parentName="blockquote">{`3.`}<a parentName="p" {...{
          "href": "https://www.guru.com/"
        }}>{`Guru`}</a></p>
      <p parentName="blockquote">{`4.`}<a parentName="p" {...{
          "href": "https://dribbble.com/"
        }}>{`Dribbble`}</a></p>
    </blockquote>
    <h2>{`What are the main challenges for Rubrain?`}</h2>
    <p>{`In Russia, we have few strong agencies. They are taking most of the market because they are operating for many years.`}</p>
    <p>{`Anyway, we are not competing with them directly. We are positioning ourselves more as a platform than an agency.`}</p>
    <p>{`Anyway, we are getting a good amount of customers by word of mouth. It is very effective in Russia.`}</p>
    <h2>{`Which tools do you use daily with your team?`}</h2>
    <p>{`We are using the Google suite of tools for documentation and presentations.`}</p>
    <p>{`Google Drive is our current storage of all these documents and important assets.`}</p>
    <p>{`Skype is used for our daily and weekly talks.`}</p>
    <p>{`We also use Telegram. We have many different chats where we can share our thoughts with the different part of Rubrain.`}</p>
    <p>{`For examples we have chats for only Founders, Marketing team and so on.
And of course, email.`}</p>
    <blockquote>
      <b>VALUE BOX</b>
      <p parentName="blockquote">{`Do you want an alternative to these tools?`}</p>
      <p parentName="blockquote">{`1.`}<a parentName="p" {...{
          "href": "https://slack.com/"
        }}>{`Slack`}</a></p>
      <p parentName="blockquote">{`2.`}<a parentName="p" {...{
          "href": "https://hangouts.google.com/"
        }}>{`Google Hangout`}</a></p>
      <p parentName="blockquote">{`3.`}<a parentName="p" {...{
          "href": "https://www.dropbox.com"
        }}>{`Dropbox`}</a></p>
      <p parentName="blockquote">{`4.`}<a parentName="p" {...{
          "href": "https://www.facebook.com/workplace"
        }}>{`Workplace by Facebook`}</a></p>
    </blockquote>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h2>{`Last question: what’s next for Rubrain?`}</h2>
    <p>{`We had like 9000 applications from freelancers in 2016 and the number is increasing in 2017.`}</p>
    <p>{`We broke even in 2016 and so, we are looking to increase the revenue more than anything else.`}</p>
    <p>{`We have got a good amount of Russian clients and we are planning to increase this number in the following years.`}</p>
    <p>{`The target for 2018 is to enter more deeply both in the European and American market.`}</p>
    <b>Thank you very much to Rubrain for their time and insights on the freelancing world.</b>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      